import { render, staticRenderFns } from "./certification.vue?vue&type=template&id=52f7ad58&scoped=true&"
import script from "./certification.vue?vue&type=script&lang=js&"
export * from "./certification.vue?vue&type=script&lang=js&"
import style0 from "./certification.vue?vue&type=style&index=0&id=52f7ad58&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52f7ad58",
  null
  
)

export default component.exports